/**
 * Links for the NSUK and FULAFIA project
*/

export default {
  // api: "https://test.nsuk.edu.ng/api",
  // api: "https://ug.nsuk.edu.ng/api"
 api: 'http://localhost:8080/api',
  // api: 'http://192.168.0.137:8080/api',
  //  api:  "https://pg.nsuk.edu.ng/api",


  /**
   * links for the Lafia
   */
 // api: "https://my.fulafia.edu.ng/api",
 //  api: 'https://portal.fpn.edu.ng/api'





  // api: 'http://34.23.160.253/api'
};
